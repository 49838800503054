<!--
 @fileName    : Partner.vue
 @author      : llz
 @date        : Created in 2022-04-15 15:03:45
 @description : 合作方
 @version     : 1.0

 鹿乃yyds!!!
-->
<template>
  <el-card>
    <main-header main-title="合作方">
      <template #btn>
        <el-button type="primary" @click="addOrUpdatePartner()">创建合作方</el-button>
      </template>
    </main-header>
    <div class="partner">
      <el-form :inline="true" ref="ptrSearchForm" :model="searchForm">
        <el-form-item prop="clientCode">
          <el-select v-model="searchForm.clientCode" placeholder="请选择合作方" filterable>
            <el-option v-for="item in partnerList" :value="item.clientCode" :label="item.clientName" :key="item.clientCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="doSearch()">查询</el-button>
          <el-button type="success" @click="resetSearch('ptrSearchForm')">重置</el-button>
          <!--<el-button type="primary" @click="addOrUpdatePartner()">创建合作方</el-button>-->
        </el-form-item>
      </el-form>
      <el-table :data="partnerTableData" border v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="clientName" header-align="center" align="center" label="合作方名称"></el-table-column>
        <el-table-column prop="clientCode" header-align="center" align="center" label="合作方编号"></el-table-column>
        <el-table-column prop="clientTel" header-align="center" align="center" label="负责人手机号" :formatter="telFormatter"></el-table-column>
        <el-table-column prop="creationTime" header-align="center" align="center" label="创建时间"></el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="180" label="操作">
          <template v-slot="scope">
            <el-button type="text" size="small" @click="addOrUpdatePartner(scope.row.clientCode, scope.row.clientName, scope.row.clientTel)">编辑</el-button>
            <el-button type="text" size="small" @click="deletePartner(scope.row.clientCode)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalSize"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
    <partner-add-or-update v-if="addOrUpdateVisible" ref="partnerAddOrUpdate" @refreshDataList="refreshPartnerDataList()"></partner-add-or-update>
  </el-card>
</template>

<script>
import PartnerAddOrUpdate from "./Partner-add-or-update";
import {clientInfoDeleteByCode, clientInfoQueryAll, clientInfoQueryByPage} from "../../api/partner";
import MainHeader from "../../components/layout/mainHeader";

export default {
  name: "Partner",
  components: {MainHeader, PartnerAddOrUpdate},
  data() {
    return {
      partnerList: [],
      searchForm: {
        clientCode: ''
      },
      //点击查询按钮后保存并带入提交的搜索条件，防止用户输入条件后直接去切换分页导致页码溢出
      submitSearchForm: {
        clientCode: ''
      },

      partnerTableData: [
        // {
        //   partner_name: '学易达',
        //   partner_code: 'xueyida',
        //   phone_code: 13355558888,
        //   create_time: '2022-04-18'
        // },
        // {
        //   partner_name: '学海游',
        //   partner_code: 'xuehaiyou',
        //   phone_code: 13355558888,
        //   create_time: '2022-04-18'
        // },
      ],

      pageIndex: 1,
      pageSize: 10,
      totalSize: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
    }
  },
  activated() {
    this.getPartnerList()
    this.getPartnerDataList()
  },
  methods: {
    getPartnerList() {
      clientInfoQueryAll().then((res) => {
        console.log(res)
        if (res && res.length > 0) {
          this.partnerList = res
          // this.$set()
        }
      })
    },

    getPartnerDataList() {
      //用户输入筛选条件后直接切换分页(不点查询)会导致页数溢出bug。因此分页接口查询时提交submitSearchForm即可
      this.dataListLoading = true
      clientInfoQueryByPage(this.submitSearchForm.clientCode, this.pageIndex, this.pageSize).then((res) => {
        if (res && res.code === 1) {
          this.partnerTableData = res.data.content
          this.totalSize = res.data.totalElements
        } else {
          this.partnerTableData = []
          this.totalSize = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getPartnerDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getPartnerDataList()
    },

    //点击查询(在这里更新搜索条件)
    doSearch() {
      this.submitSearchForm.clientCode = this.searchForm.clientCode
      this.pageIndex = 1
      this.getPartnerDataList()
    },

    //重置搜索条件查询
    resetSearch(formName) {
      this.$refs[formName].resetFields()
      this.submitSearchForm.clientCode = ''
      this.pageIndex = 1
      this.getPartnerDataList()
    },

    //新增或编辑
    addOrUpdatePartner(code, name, tel) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.partnerAddOrUpdate.init(code, name, tel)
      })
    },
    /**
     * 删除合作方
     * @param code
     */
    deletePartner(code) {
      this.$confirm('确定要删除该合作方?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        clientInfoDeleteByCode(code).then((res) => {
          if (res && res.code === 1) {
            this.$message({
              message: '删除合作方成功!',
              type: 'success',
              duration: 2000,
              onClose: () => {
                this.refreshPartnerDataList()//刷新父组件的表格内容
              }
            })
          } else {
            this.$message.error(res.data[0])
          }
        })
      })
    },

    refreshPartnerDataList() {
      this.pageIndex = 1
      this.getPartnerDataList()
      this.getPartnerList() //下拉表单也要刷新
    },

    telFormatter(row, column, cellValue) {
      if (cellValue === "" || cellValue === undefined) {
        return "未填写"
      }
      return cellValue
    }
  }
}
</script>

<style lang="scss" scoped>
.el-card {
  height: 100%;

  .partner {
    padding: 15px 10px;
  }
}
</style>
