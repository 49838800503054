<template>
  <el-dialog :title="!dataForm.partnerCode ? '新增' : '修改'"
             :close-on-click-modal="false"
             :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="partnerForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-form-item label="合作方编号" prop="partnerCode" v-if="dataForm.partnerCode">
        <div>{{ dataForm.partnerCode }}</div>
      </el-form-item>
      <el-form-item label="合作方名称" prop="partnerName">
        <el-input v-model="dataForm.partnerName" placeholder="请输入合作方名称"></el-input>
      </el-form-item>
      <el-form-item label="合作方手机号" prop="phoneCode">
        <el-input v-model="dataForm.phoneCode" placeholder="合作方手机号(选填)"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {clientInfoInsert, clientInfoUpdate} from "../../api/partner";

export default {
  name: "Partner-add-or-update",
  data() {
    let telValidater = (rule, value, callback) => {
      let telStr = /^[1][0-9]{10}$/
      if (value !== '' && !telStr.test(value)) {
        callback(new Error('请输入正确格式的手机号'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      dataForm: {
        partnerCode: '',
        partnerName: '',
        phoneCode: ''
      },
      dataRule: {
        partnerName: [
          {required: true, message: '合作方名称不能为空', trigger: 'blur'}
        ],
        phoneCode: [
          {validator: telValidater, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(code, name, tel) {
      console.log(code)
      this.dataForm.partnerCode = code || ''
      this.visible = true
      this.$nextTick(() => {
        this.$refs["partnerForm"].resetFields()
        if (this.dataForm.partnerCode) { //编辑
          this.dataForm.partnerName = name
          this.dataForm.phoneCode = tel
        }
      })
    },

    dataFormSubmit() {
      this.$refs['partnerForm'].validate((valid) => {
        if (valid) {
          if (this.dataForm.partnerCode) { //编辑
            clientInfoUpdate(this.dataForm.partnerCode, this.dataForm.partnerName, this.dataForm.phoneCode).then((res) => {
              if (res && res.code === 1) {
                this.$message.success("编辑合作方成功")
                this.visible = false
                this.$emit('refreshDataList') //刷新表格
              } else {
                this.$message.error(res.data[0])
              }
            })
          } else { //新增
            clientInfoInsert(this.dataForm.partnerName, this.dataForm.phoneCode).then((res) => {
              if (res && res.code === 1) {
                this.$message.success("新增合作方成功")
                this.visible = false
                this.$emit('refreshDataList') //刷新表格
              } else {
                this.$message.error(res.data[0])
              }
            })
          }
        } else {
          this.$message.error("保存失败!")
          return false;
        }
      });


    }
  }
}
</script>

<style scoped>

</style>
